import React, { memo, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../components/shared/Button';
import ModalContext from '../../contexts/ModalContext';
import { useDispatch } from '../../contexts/ResumeContext';
import BaseModal from '../BaseModal';

const ImportModal = () => {
  const { t } = useTranslation();
  const reactiveResumeFileInputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const { emitter, events } = useContext(ModalContext);

  useEffect(() => {
    const unbind = emitter.on(events.IMPORT_MODAL, () => setOpen(true));

    return () => unbind();
  }, [emitter, events]);

  const importReactiveResumeJson = (event) => {
    const fr = new FileReader();
    fr.addEventListener('load', () => {
      const payload = JSON.parse(fr.result);
      dispatch({ type: 'on_import', payload });
      setOpen(false);
    });
    fr.readAsText(event.target.files[0]);
  };

  return (
    <BaseModal
      hideActions
      state={[open, setOpen]}
      title={t('builder.actions.import.heading')}
    >
      <div>
        <p className="leading-loose">
          {t('modals.import.reactiveResume.text')}
        </p>

        <Button
          className="mt-5"
          onClick={() => reactiveResumeFileInputRef.current.click()}
        >
          {t('modals.import.button')}
        </Button>
        <input
          ref={reactiveResumeFileInputRef}
          type="file"
          className="hidden"
          onChange={importReactiveResumeJson}
        />
      </div>
    </BaseModal>
  );
};

export default memo(ImportModal);
