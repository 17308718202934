import React, { createContext, memo, useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import { pick } from 'lodash';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { getAuth, signInWithPopup, signOut, GoogleAuthProvider } from "firebase/auth";

import useFirebase from '../hooks/useFirebase';
import useAuthState from '../hooks/useAuthState';

const defaultUser = {
  uid: null,
  email: null,
  displayName: null,
  endDate: null
};

const defaultState = {
  loading: false,
  user: defaultUser,
  logout: async () => {},
  loginWithGoogle: async () => {},
  deleteAccount: async () => {},
};

const UserContext = createContext(defaultState);

const UserProvider = ({ children }) => {
  const { t } = useTranslation();
  const firebase = useFirebase();
  const [firebaseUser, loading] = useAuthState(firebase);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const localUser = JSON.parse(localStorage.getItem('user'));
    setUser(localUser);
  }, []);

  useEffect(() => {
    (async function() {
      if (firebaseUser) {
        const remoteUser = pick(firebaseUser, Object.keys(defaultUser));
        const userRef = firebase.database().ref(`users/${remoteUser.uid}`);
        const snapshot = await userRef.once('value');
        const value = snapshot.val();

        if (value && value?.endDate) {
          remoteUser.endDate = value.endDate;
        }

        localStorage.setItem('user', JSON.stringify(remoteUser));
        setUser(remoteUser);

        const addUserToDatabase = async () => {
          !snapshot.val() && userRef.set(remoteUser);
        };

        addUserToDatabase();
      }
    })()
  }, [firebaseUser]);

  const setTimeStamp = async (timestamp) => {
    const remoteUser = {...user, endDate: timestamp};
    localStorage.setItem('user', JSON.stringify(remoteUser));
    setUser(remoteUser);

    const userRef = firebase.database().ref(`users/${remoteUser.uid}`);
    userRef.update({
      endDate: timestamp
    });
  }

  const loginWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();

    try {
      await signInWithPopup(auth, provider)
    } catch(error) {
      toast.error(error.message);
    }
  };

  const logout = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      localStorage.removeItem('user');
      setUser(null);
      navigate('/');
    }).catch((error) => {
      toast.error(error.message);
    });
  }

  const deleteAccount = async () => {
    const { currentUser } = firebase.auth();
    const deleteUser = firebase.functions().httpsCallable('deleteUser');

    try {
      deleteUser({
        uid: currentUser.uid
      });
    } catch (e) {
      if (e.code === 'auth/requires-recent-login') {
        await loginWithGoogle();
        await currentUser.delete();
      }
    } finally {
      logout();
      toast(t('builder.toasts.removeAccount'));
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        logout,
        loading,
        setTimeStamp,
        loginWithGoogle,
        deleteAccount,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;

const memoizedProvider = memo(UserProvider);

export { memoizedProvider as UserProvider };
