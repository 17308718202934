import React, { createContext, memo, useContext, useState } from 'react';
import { debounce } from 'lodash';
import ShortUniqueId from 'short-unique-id';
import { useTranslation } from 'react-i18next';
import {
  getDatabase,
  get,
  child,
  remove,
  ref,
  set,
  update,
} from 'firebase/database';

import UserContext from './UserContext';
import initialState from '../data/initialState.json';
import { getUnsplashPhoto } from '../utils';

const DEBOUNCE_WAIT_TIME = 3000;

const defaultState = {
  isUpdating: false,
  createResume: async () => {},
  duplicateResume: async () => {},
  deleteResume: () => {},
  getResume: async () => {},
  updateResume: async () => {},
  debouncedUpdateResume: async () => {},
};

const DatabaseContext = createContext(defaultState);

const DatabaseProvider = ({ children }) => {
  const dictionary = 'abcdefghijklmnopqrstuvwxyz1234567890'.split('');
  const uuid = new ShortUniqueId({ dictionary });
  const { t } = useTranslation();
  const [isUpdating, setUpdating] = useState(false);
  const { user } = useContext(UserContext);

  const getResume = async (id) => {
    const dbRef = ref(getDatabase());

    return get(child(dbRef, `resumes/${id}`)).then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.val();
      }
    });
  };

  const createResume = async ({ name }) => {
    const id = uuid();
    const preview = await getUnsplashPhoto();
    const createdAt = new Date().getTime();

    let firstName;
    let lastName;

    if (!user.isAnonymous) {
      [firstName, lastName] = user.displayName.split(' ');
    }

    const resume = {
      ...initialState,
      id,
      name,
      user: user.uid,
      preview,
      profile: {
        ...initialState.profile,
        firstName: firstName || '',
        lastName: lastName || '',
      },
      createdAt,
      updatedAt: createdAt,
    };

    const db = getDatabase();
    await set(ref(db, `resumes/${id}`), resume);
  };

  const duplicateResume = async (originalResume) => {
    const id = uuid();
    const preview = await getUnsplashPhoto();
    const createdAt = new Date().getTime();

    const resume = {
      ...originalResume,
      id,
      name: `${originalResume.name} ${t('shared.copy')}`,
      preview,
      createdAt,
      updatedAt: createdAt,
    };

    const db = getDatabase();
    await set(ref(db, `resumes/${id}`), resume);
  };

  const updateResume = async (resume) => {
    setUpdating(true);
    const db = getDatabase();
    const updatedAt = new Date().getTime();

    await update(ref(db, `resumes/${resume.id}`), {
      ...resume,
      updatedAt,
    });
    setTimeout(() => setUpdating(false), 2000);
  };

  const debouncedUpdateResume = debounce(updateResume, DEBOUNCE_WAIT_TIME);

  const deleteResume = async (id) => {
    const db = getDatabase();
    await remove(ref(db, `/resumes/${id}`));
  };

  return (
    <DatabaseContext.Provider
      value={{
        isUpdating,
        getResume,
        createResume,
        duplicateResume,
        updateResume,
        deleteResume,
        debouncedUpdateResume,
      }}
    >
      {children}
    </DatabaseContext.Provider>
  );
};

export default DatabaseContext;

const memoizedProvider = memo(DatabaseProvider);

export {
  memoizedProvider as DatabaseProvider,
  DEBOUNCE_WAIT_TIME as DebounceWaitTime,
};
