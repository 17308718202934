const themeConfig = {
  '--color-primary-50': '#2a2a2a',
  '--color-primary-100': '#2c2c2c',
  '--color-primary-200': '#424242',
  '--color-primary-300': '#616161',
  '--color-primary-400': '#757575',
  '--color-primary-500': '#9e9e9e',
  '--color-primary-600': '#bdbdbd',
  '--color-primary-700': '#e0e0e0',
  '--color-primary-800': '#eeeeee',
  '--color-primary-900': '#f5f5f5',
}

export default themeConfig;
