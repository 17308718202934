import React, { memo, useContext, useEffect, useState } from 'react';
import download from 'downloadjs';
import { clone } from 'lodash';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import useFirebase from '../../hooks/useFirebase';
import { useTranslation } from 'react-i18next';
import Button from '../../components/shared/Button';
import ModalContext from '../../contexts/ModalContext';
import { useSelector } from '../../contexts/ResumeContext';
import { b64toBlob } from '../../utils';
import BaseModal from '../BaseModal';
import UserContext from '../../contexts/UserContext';

const ExportModal = () => {
  const state = useSelector();
  const firebase = useFirebase();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isShowPopup, setPopup] = useState(false);
  const [isLoadingSingle, setLoadingSingle] = useState(false);
  const [, setLoadingMulti] = useState(false);
  const { user, setTimeStamp } = useContext(
    UserContext,
  );

  const { emitter, events } = useContext(ModalContext);

  // useEffect(() => {
  //     if (typeof window === 'object') {
  //       if (isShowPopup) {
  //         window.LiqPayCheckoutCallback = function() {
  //           LiqPayCheckout.init({
  //             data: "eyJ2ZXJzaW9uIjozLCJhY3Rpb24iOiJwYXkiLCJhbW91bnQiOiI1MCIsImN1cnJlbmN5IjoiVUFIIiwiZGVzY3JpcHRpb24iOiJSZXN1bWUiLCJwdWJsaWNfa2V5Ijoic2FuZGJveF9pOTI3NTYzNDIwNDAiLCJsYW5ndWFnZSI6InVrIn0=",
  //             signature: "4hWuQQCTOOMKjlxCpQAq2R25Jxg=",
  //             mode: "popup"
  //           }).on("liqpay.callback", function(data) {
  //             if (data.status === 'success') {
  //               setTimeStamp(dayjs(data.create_date).add(3, 'day').valueOf());
  //             }
  //             setPopup(false);
  //           }).on("liqpay.ready", function(data) {
  //
  //           }).on("liqpay.close", function(data) {
  //             setPopup(false);
  //           });
  //         }
  //         window.LiqPayCheckoutCallback();
  //       }
  //   }
  // }, [isShowPopup])

  useEffect(() => {
    const unbind = emitter.on(events.EXPORT_MODAL, () => setOpen(true));
    return () => unbind();
  }, [emitter, events]);

  const handleDownload = async (isSinglePDF) => {
    isSinglePDF ? setLoadingSingle(true) : setLoadingMulti(true);

    try {
      const printResume = firebase.functions().httpsCallable('printResume');
      const { data } = await printResume({
        id: state.id,
        type: isSinglePDF ? 'single' : 'multi',
      });

      const blob = b64toBlob(data, 'application/pdf');

      download(blob, `myresume-${state.id}.pdf`, 'application/pdf');
    } catch (error) {
      toast(t('builder.toasts.printError'));
    } finally {
      isSinglePDF ? setLoadingSingle(false) : setLoadingMulti(false);
    }
  };

  const handleExportToJson = () => {
    const backupObj = clone(state);
    delete backupObj.id;
    delete backupObj.user;
    delete backupObj.name;
    delete backupObj.createdAt;
    delete backupObj.updatedAt;
    const data = `data:text/json;charset=utf-8,${encodeURIComponent(
      JSON.stringify(backupObj, null, '\t'),
    )}`;
    download(data, `myresume-${state.id}.json`, 'text/json');
  };

  // const hasEndDate = user?.endDate && new Date().getTime() < user?.endDate;
  //  {`(${dayjs(user?.endDate).locale(i18n.language).fromNow(true)})`}
  const hasEndDate = true;

  return (
    <BaseModal
      hideActions
      state={[open, setOpen]}
      title={t('builder.actions.export.heading')}
    >
      <div>
        <p className="leading-loose">{t('modals.export.downloadPDF.text')}</p>

        <div className="mt-5 mb-8">
          <div className="flex">
            {hasEndDate ? <Button
              isLoading={isLoadingSingle}
              isSpinner={isLoadingSingle}
              onClick={() => handleDownload(true)}
            >
              {t('modals.export.downloadPDF.buttons.single')}
            </Button> :
              <Button
              onClick={() => setPopup(true)}
            >
              {t('modals.export.downloadPDF.buttons.single')} {t('modals.export.downloadPDF.buttons.label')} {`(${t('modals.export.downloadPDF.buttons.info')})`}
            </Button>}
          </div>
        </div>
      </div>

      <hr className="my-8" />

      <div>
        <h5 className="text-xl font-semibold mb-4">
          {t('modals.export.jsonFormat.heading')}
        </h5>

        <p className="leading-loose">{t('modals.export.jsonFormat.text')}</p>

        <div className="mt-5">
          <Button onClick={handleExportToJson}>
            {t('modals.export.jsonFormat.button')}
          </Button>
          <a id="downloadAnchor" className="hidden">
            {t('modals.export.jsonFormat.button')}
          </a>
        </div>
      </div>
    </BaseModal>
  );
};

export default memo(ExportModal);
