import i18next from 'i18next';
import React, { createContext, memo, useEffect, useState } from 'react';
import themeConfig from '../data/themeConfig';

const languageStorageItemKey = 'language';

const defaultState = {
  language: 'uk',
  setLanguage: () => {},
};

const SettingsContext = createContext(defaultState);

const SettingsProvider = ({ children }) => {
  const [language, setLanguage] = useState(defaultState.language);

  useEffect(() => {
    const prefLanguage =
      localStorage.getItem(languageStorageItemKey) || defaultState.language;
    setLanguage(prefLanguage);
  }, []);

  useEffect(() => {
    for (const [key, value] of Object.entries(themeConfig)) {
      document.documentElement.style.setProperty(key, value);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(languageStorageItemKey, language);
    i18next.changeLanguage(language);
  }, [language]);

  return (
    <SettingsContext.Provider
      value={{
        language,
        setLanguage,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContext;

const memoizedProvider = memo(SettingsProvider);

export { memoizedProvider as SettingsProvider, languageStorageItemKey };
