import { useEffect, useState } from 'react';
import getFirebase from './firebase';

export default function useFirebase() {
    const [instance, setInstance] = useState(null);
    const firebase = getFirebase();

    useEffect(() => {
        if (!firebase) return;
        setInstance(firebase);
    }, [firebase]);

    return instance;
}
