import cx from 'classnames';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { handleKeyUp } from '../../utils';
import * as styles from './Button.module.css';
import { FaSpinner } from 'react-icons/fa';

const Button = ({
  icon,
  onClick,
  outline,
  primary,
  secondary,
  children,
  className,
  isSpinner = false,
  isDisabled = false,
  isLoading,
  isDelete,
}) => {
  const { t } = useTranslation();
  const Icon = icon;

  return (
    <button
      onKeyUp={(e) => handleKeyUp(e, onClick)}
      onClick={isLoading ? undefined : onClick}
      className={cx(styles.container, className, {
        [styles.outline]: outline,
        [styles.primary]: primary,
        [styles.secondary]: secondary,
        [styles.del]: isDelete,
        [styles.disabled]: isDisabled,
        [styles.loading]: isLoading
      })}
    >
      {icon && <Icon size="14" className="mr-3" />}
      {isLoading ? t('shared.buttons.loading') : children}
      {isSpinner && <FaSpinner className="ml-3 animate-spin" spin />}
    </button>
  );
};

export default memo(Button);
