exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-blog-js": () => import("./../../../src/components/Blog.js" /* webpackChunkName: "component---src-components-blog-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-app-builder-js": () => import("./../../../src/pages/app/builder/[...].js" /* webpackChunkName: "component---src-pages-app-builder-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app/[...].js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-public-offer-js": () => import("./../../../src/pages/public-offer.js" /* webpackChunkName: "component---src-pages-public-offer-js" */),
  "component---src-pages-r-js": () => import("./../../../src/pages/r/[...].js" /* webpackChunkName: "component---src-pages-r-js" */),
  "component---src-pages-stat-js": () => import("./../../../src/pages/stat.js" /* webpackChunkName: "component---src-pages-stat-js" */)
}

