import React from 'react';
import { createTheme, MuiThemeProvider } from '@material-ui/core';
import { Slide, ToastContainer } from 'react-toastify';
import { Script } from "gatsby"
import 'animate.css';

import ModalRegistrar from './src/modals/ModalRegistrar';
import { DatabaseProvider } from './src/contexts/DatabaseContext';
import { ModalProvider } from './src/contexts/ModalContext';
import { ResumeProvider } from './src/contexts/ResumeContext';
import { SettingsProvider } from './src/contexts/SettingsContext';
import { StorageProvider } from './src/contexts/StorageContext';
import { UserProvider } from './src/contexts/UserContext';
import './src/i18n';
import './src/styles/forms.css';
import './src/styles/global.css';
import './src/styles/shadows.css';
import './src/styles/tailwind.css';
import './src/styles/toastify.css';
import './src/styles/markdown.css';
import './src/styles/main.scss';
import './src/utils/dayjs';

const theme = createTheme({
  palette: {
    type: 'dark',
  },
  typography: {
    fontFamily: ['Nunito', 'sans-serif'].join(','),
    fontSize: 14,
    fontWeightRegular: 500,
  },
});

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }) => (
  <SettingsProvider>
    <MuiThemeProvider theme={theme}>
      <ModalProvider>
        <UserProvider>
          <DatabaseProvider>
            <ResumeProvider>
              <StorageProvider>{element}</StorageProvider>
            </ResumeProvider>
          </DatabaseProvider>
        </UserProvider>
      </ModalProvider>
    </MuiThemeProvider>
    <Script id="liqpay-checkout" src="https://static.liqpay.ua/libjs/checkout.js" async />
  </SettingsProvider>
);

export function wrapPageElement({ element, props: { location } }) {
  return (
    <>
      {element}
      <ModalRegistrar />
      <ToastContainer role="alert" rtl={false} transition={Slide} pauseOnFocusLoss={false} newestOnTop={false} closeButton={false} position="bottom-right" theme="dark" hideProgressBar />
    </>
  );
}
