import { Formik } from 'formik';
import React, { memo } from 'react';
import * as Yup from 'yup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { useTranslation } from 'react-i18next';
import Input from '../../components/shared/Input';
import ModalEvents from '../../constants/ModalEvents';
import { getFieldProps } from '../../utils';
import DataModal from '../DataModal';

const initialValues = {
  company: '',
  position: '',
  website: '',
  startDate: '',
  endDate: '',
  summary: '',
  switch: false,
};

const WorkModal = () => {
  const { t } = useTranslation();

  const schema = Yup.object().shape({
    company: Yup.string().required(t('shared.forms.validation.required')),
    position: Yup.string().required(t('shared.forms.validation.required')),
    website: Yup.string().url(t('shared.forms.validation.url')),
    startDate: Yup.date(),
    endDate: Yup.date().when(
      'startDate',
      (startDate, yupSchema) =>
        startDate &&
        yupSchema.min(startDate, t('shared.forms.validation.dateRange')),
    ),
    summary: Yup.string().min(
      10,
      t('shared.forms.validation.min', { number: 10 }),
    ),
    switch: Yup.boolean()
  });

  return (
    <Formik
      validateOnBlur
      initialValues={initialValues}
      validationSchema={schema}
    >
      {(formik) => {
        return <DataModal
          path="work.items"
          name={t('builder.sections.work')}
          event={ModalEvents.WORK_MODAL}
        >
          <div className="grid grid-cols-2 gap-8">
            <Input
              label={t('builder.work.company')}
              className="col-span-2"
              placeholder="Global Logic"
              {...getFieldProps(formik, schema, 'company')}
            />

            <Input
              label={t('shared.forms.position')}
              placeholder="Full Stack Web Developer"
              {...getFieldProps(formik, schema, 'position')}
            />

            <Input
              label={t('shared.forms.website')}
              placeholder="https://"
              {...getFieldProps(formik, schema, 'website')}
            />

            <Input
              type="date"
              label={t('shared.forms.startDate')}
              {...getFieldProps(formik, schema, 'startDate')}
            />

            <div className="col-span-1">
              <Input
                type="date"
                label={t('shared.forms.endDate')}
                isDisabled={formik.values.switch}
                {...getFieldProps(formik, schema, 'endDate')}
              />
              <FormControlLabel
                className="mt-2 custom-label"
                control={
                  <Switch
                    color="primary"
                    checked={formik.values.switch}
                    onChange={(e) => formik.setFieldValue('switch', e.target.checked)}
                    name="switch"
                  />
                }
                label={t('shared.forms.switch')}
              />
            </div>

            <Input
              type="textarea"
              label={t('shared.forms.summary')}
              className="col-span-2"
              {...getFieldProps(formik, schema, 'summary')}
            />
          </div>
        </DataModal>
      }}
    </Formik>
  );
};

export default memo(WorkModal);
