import { Formik } from 'formik';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import Input from '../../components/shared/Input';
import ModalEvents from '../../constants/ModalEvents';
import { getFieldProps } from '../../utils';
import levelProficiency from '../../data/levelProficiency';
import languageOptions from '../../data/languageOptions';
import DataModal from '../DataModal';
import levelEducation from '../../data/levelEducation';

const initialValues = {
  name: '',
  fluency: '',
};

const LanguageModal = () => {
  const { t } = useTranslation();

  const schema = Yup.object().shape({
    name: Yup.string().required(t('shared.forms.validation.required')),
  });

  const levelOptions = levelProficiency.map((item) => {
    const str = `builder.languages.level.${item}`;
    return `${item} - ${t(str)}`;
  });

  const lgOptions = [''].concat(languageOptions.map((item) => t(`builder.languages.variants.${item}`)));

  return (
    <Formik
      validateOnBlur
      initialValues={initialValues}
      validationSchema={schema}
    >
      {(formik) => (
        <DataModal
          name={t('builder.sections.language')}
          path="languages.items"
          event={ModalEvents.LANGUAGE_MODAL}
        >
          <div className="grid grid-cols-2 gap-8">
            <Input
              label={t('builder.languages.choose')}
              type="dropdown"
              options={lgOptions}
              placeholder={t('builder.languages.placeholder')}
              {...getFieldProps(formik, schema, 'name')}
            />

            <Input
              label={t('builder.languages.fluency')}
              type="dropdown"
              options={levelOptions}
              {...getFieldProps(formik, schema, 'fluency')}
            />
          </div>
        </DataModal>
      )}
    </Formik>
  );
};

export default memo(LanguageModal);
